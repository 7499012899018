
@import '../../../../scss/vars.scss';

.about {
	position: relative;

	&__desc {
		align-items: baseline;

		padding: 5px 10px;
	}

	&__inner {
		display: flex;
		align-items: center;
		flex-direction: column;
	}

	&__content-block {
		padding: 70px 20px;
	}
    
	&__text {
		display: flex;
		align-items: center;
		flex-direction: column;

		text-align: center;

		font-size: 20px;
		font-weight: 700;
		@extend%libreBaskerville-700;
	}

	&__text-accent {
		padding: 0 5px;

		color: $hover;
	}

	&__text-desc {
		color: $default;
	}
    
	&__meta-text {
		font-size: 24px;
		font-weight: 400;
	}
}

@media screen and (width <= 1024px) {
	.about {
		.line {
			display: none;
		}

		&__text {
			margin-bottom: 50px;

			font-size: 24px;
		}

		&__meta-text {
			font-size: 14px;
		}

		&__content {
			& a:last-child {
				margin-right: 35px;
			}
		}

		&__content-block {
			padding-top: 20px;
		}

		&__link {
			width: 157px;
			height: 157px;
			margin-right: 20px;

			&:hover{
				box-shadow: none;
			}
		}

		&__card-desc {
			font-size: 18px;
		}
	}
}

@media screen and (width <= 767px) {
	.about {
		&__desc {
			left: -5px;
		}
	}
}

@media screen and (width <= 468px) {
	.about {
		&__desc {
			display: flex;
			justify-content: center;

			width: 100%;
		}

		&__text{
			margin-bottom: 20px;

			font-size: 18px;
		}

		&__content-block {
			padding-top: 0;
			padding-bottom: 10px;
		}

		&__content {
			position: relative;

			flex-direction: column;

			margin-right: 0;

			& a {
				width: 100%;
				margin-bottom: 20px;
			}
		}

		&__link {
			width: 100%;
			height: 44px;
			padding: 12px;

			border-radius: 0 18px 18px;
		}
	}
}


