.header {
	position: sticky;
	z-index: 1000;
	top: 30px;
	top: 0;

	width: 100%;
	padding: 10px 0;

	background-color: #FFF;
	box-shadow: 0 0 20px -3px #282535;
  
	&__inner,
	&__box {
		display: flex;
		align-items: center;
		justify-content: space-between;
	}
  
	&__box {
		position: relative;

		max-width: 707px;
	}
  
	&__phone-block {
		display: flex;
		align-items: flex-end;
		flex-direction: column;
	}
  
	&__desc {
		white-space: nowrap;
		text-transform: uppercase;

		font-size: 12px;
		font-weight: 400;
	}
  
	&__subdesc {
		transition: color 0.3s;
		white-space: nowrap;

		color: $default;

		font-size: 20px;

		&:hover {
			color: $hover;
		}

		@extend %helvetica-700;
	}

	&__popup {
		display: none;
		align-items: center;

		height: 0;

		user-select: none;
		transition: height 0.3s, opacity 0.3s, margin-right 0.5s, width 0.5s;
		white-space: nowrap;
		text-transform: uppercase;

		opacity: 0;
		border: none;
		background-color: #FFF;

		&.active {
			margin-top: 5px;
			margin-right: 0;

			.header__popup-line {
				height: 0;

				&::before {
					top: -3px;

					margin-bottom: 0;

					transform: rotate(45deg);
				}

				&::after {
					bottom: 1px;

					transform: rotate(-45deg);
				}
			}
		}
	}

	&__popup-line{
		position: relative;

		display: inline-block;

		width: 20px;
		height: 2px;
		margin-right: 15px;
		margin-bottom: 5px;

		background-color: $default;

		&::before,
		&::after {
			position: absolute;
			right: 0;

			width: 20px;
			height: 2px;

			content: '';
			transition: transform 0.3s;

			background-color: $default;
		}

		&::before {
			top: -7px;      
		}

		&::after {
			bottom: -7px;
		}
	}

	.help {
		margin-bottom: -10px;
	}
}

.oriental-mobile {
	display: none;
}

.header__cart {
	position: relative;

	&-count {
		position: absolute;
		top: -2px;
		right: -4px;

		display: flex;
		align-items: center;
		justify-content: center;

		width: 14px;
		height: 14px;
		padding-top: 4px;

		border-radius: 50%;
		background-color: #F2E9E9;
		box-shadow: rgb(0 0 0 / 2%) 0 1px 3px 0, rgb(27 31 35 / 15%) 0 0 0 1px;

		font-size: 12px;
	}

	&-img {
		padding: 2px;

		transition: box-shadow 0.3s;

		border-radius: 50%;

		&:hover {
			transition: box-shadow 0.3s;

			box-shadow: rgb(0 0 0 / 2%) 0 1px 3px 0, rgb(27 31 35 / 15%) 0 0 0 1px;
		}
	}
}

@media screen and (width <= 1024px) {
	.oriental-mobile {
		display: inline-block;
	}

	.oriental {
		display: none;

		margin-left: 75px;
	}

	.header {
		&__img {
			display: none;
		}
    
		&__popup {
			display: inline-block;

			height: 100%;
			margin-right: 61%;
			margin-left: 0;

			opacity: 1;
		}

		&__phone-block {
			height: 100%;

			transition: transform 0.3s;
			transform: translateY(0%);

			opacity: 1;

			& p,
			& a {
				display: inline-block;
			}
		}

		&__inner.active{
			.header__phone-block {
				width: 0;
				height: 0;

				transition: transform 1s;
				transform: translateX(400%);

				opacity: 0;

				& p,
				& a {
					display: none;
				}
			}

			.icon-location {
				font-size: 24px;
			}

			.icon-phone {
				font-size: 22px;
			}

			.icon-mail {
				padding-top: 4px;

				font-size: 19px;
			}
		}

		&__box {
			max-width: 100%;
		}
	}
}

@media screen and (width <= 767px) {
	.header {
		&__inner.active {
			.menu {
				height: 50%;
				padding-top: 45px;
				padding-bottom: 20px;
			}
		}

		&__subdesc {
			font-size: 18px;
		}
	}

	.oriental {
		margin-right: 75px;
	}

	.contacts-btn {
		display: none;
	}
}

@media screen and (width <= 500px) {
	.oriental-mobile {
		display: none;
	}
}

@media screen and (width <= 468px) {
	.header {
		&__box {
			margin-left: -5px;
		}

		&__popup {
			margin-left: 0;
		}

		&__subdesc {
			font-size: 18px;
		}

		.oriental {
			display: none;
		}
	}
}