@font-face {
  font-family: 'HelveticaNeueCyr';
  font-weight: 400;
  font-style: normal;
  src: url('../assets/fonts/HelveticaNeueCyr-Roman.woff') format('woff');
  font-display: swap;
}

@font-face {
  font-family: 'HelveticaNeueCyr';
  font-weight: 700;
  font-style: normal;
  src: url('../assets/fonts/HelveticaNeueCyr-Bold.woff') format('woff');
  font-display: swap;
}

@font-face {
  font-family: 'HelveticaNeueCyr';
  font-weight: 900;
  font-style: normal;
  src: url('../assets/fonts/HelveticaNeueCyr-Black.woff') format('woff');
  font-display: swap;
}


@font-face {
  font-family: 'LibreBaskerville';
  font-weight: 400;
  font-style: normal;
  src: url('../assets/fonts/LibreBaskerville-Regular.ttf') format('truetype');
  font-display: swap;
}

@font-face {
  font-family: 'LibreBaskerville-Italic';
  font-weight: 400;
  font-style: italic;
  src: url('../assets/fonts/LibreBaskerville-Italic.ttf') format('truetype');
  font-display: swap;
}

@font-face {
  font-family: 'LibreBaskerville';
  font-weight: 700;
  font-style: normal;
  src: url('../assets/fonts/LibreBaskerville-Bold.ttf') format('truetype');
  font-display: swap;
}

@font-face {
  font-family: 'Montserrat';
  font-weight: 400;
  font-style: normal;
  src: url('../assets/fonts/Montserrat-Regular.woff') format('woff');
  font-display: swap;
}

@font-face {
  font-family: 'Montserrat';
  font-weight: 700;
  font-style: normal;
  src: url('../assets/fonts/Montserrat-Bold.woff') format('woff');
  font-display: swap;
}

@font-face {
  font-family: 'icomoon';
  src:  url('../assets/fonts/icomoon.eot?b16yc1');
  src:  url('../assets/fonts/icomoon.eot?b16yc1#iefix') format('embedded-opentype'),
    url('../assets/fonts/icomoon.ttf?b16yc1') format('truetype'),
    url('../assets/fonts/icomoon.woff?b16yc1') format('woff'),
    url('../assets/fonts/icomoon.svg?b16yc1#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  // speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-arrowleft:before {
  content: "\e900";
  color: #fff;
}
.icon-arrowright:before {
  content: "\e901";
  color: #fff;
}

.icon-ardown:before {
  content: "\e300";
}
.icon-arleft:before {
  content: "\e301";
  color: #fff;
}
.icon-location:before {
  content: "\e302";
  color: #cdbaaa;
}
.icon-user:before {
  content: "\e303";
  color: #cdbaaa;
}
.icon-phone:before {
  content: "\e304";
  color: #cdbaaa;
}
.icon-phonenew:before {
  content: "\e305";
  color: #cdbaaa;
}
.icon-mail:before {
  content: "\e306";
  color: #cdbaaa;
}
.icon-instagram:before {
  content: "\e307";
  color: #fff;
}
.icon-facebook:before {
  content: "\e308";
  color: #fff;
}
.icon-calendar:before {
  content: "\e309";
}
.icon-arright:before {
  content: "\e310";
  color: #fff;
}

.icon-check:before {
  content: "\e311";
}
