@import '../../../scss/mixins.scss';

.add-to-cart  {
	display: flex;
	flex-direction: column;
	justify-content: space-between;

	.cancel {
		margin-right: 10px;
	}
}

.cart-item {
	display: flex;
	align-items: center;
	justify-content: space-between;

	margin-bottom: 50px;

	&__code {
		margin-right: 40px;

		@include _767 {
			margin-right: 5px;
		}
	}

	&__controls {
		padding: 0 20px;
	}

	&__headline {
		align-items: center;

		margin-right: 20px;

		@include _767 {
			align-items: flex-start;
			flex-direction: column;

			margin-right: 5px;

			.cart__name {
				text-align: start;
			}
		}
	}

	.cart__img {
		@include _380 {
			display: none;
		}
	}
}

.cart {
	&__name {
		display: -webkit-box;
		overflow: hidden;
		-webkit-line-clamp: 1;
		-webkit-box-orient: vertical;

		@include _767 {
			font-size: 16px!important;
		}
	}
}

.subtitle__cart {
	@include _767 {
		max-width: 300px;
	}
}