@import '../../../scss/mixins.scss';

.sub-header {
	position: relative;
	z-index: 1000;

	width: 100%;
	padding: 0 0 10px;

	background-color: red;

	@include _1024 {
		display: none;
	}
}