@import "../../scss/vars.scss";
@import "../../scss/mixins.scss";

.cart {
	&__info {
		flex: 1;

    @include _640 {
      flex-direction: column;
    }
	}

	&__inner {
		justify-content: space-between;

		min-height: 600px;
		padding-top: 20px;
	}

	&__img {
		width: 70px;
		height: 70px;
		margin-right: 20px;
    margin-left: 20px;

		background-repeat: no-repeat;
		background-position: 0 -3px;
		background-size: contain;

    @include _640 {
      margin-right: 0;
      margin-bottom: 10px;
    }
	}

  &__block-name {
    margin-right: 20px;

    @include _640 {
      margin-right: 0;
      margin-bottom: 10px;
    }
  }

  &__controls {
    margin-left: 20px;
    margin-right: 50px;

    @include _640 {
      margin-left: 0;
      margin-right: 0;
      margin-bottom: 10px;
    }
  }

  &__prices {
    flex-direction: column;
  }

	&__del-btn {
		display: flex;
		align-items: center;
		justify-content: center;

		min-width: 32px;
		height: 32px;

		cursor: pointer;
		transition: background-color 0.3s;

		border: 1px solid #F2F2F2;
		border-radius: 8px;

		&:hover {
			transition: background-color 0.3s;

			background-color: #EE7365;
		}

		&:active {
			background-color: darken($color: #EE7365, $amount: 20%)
		}
	}

	&__label {
		text-transform: lowercase;

		color: #5F5C5C;

		font-size: 14px;
	}

	&__num {
		color: $default;

		font-size: 18px;
	}

	&__name {
		font-size: 20px;
	}

	&__column {
		width: 100%;
		min-width: 100px;

    @include _640 {
      margin-right: 0;
      margin-bottom: 10px;
      text-align: center;
    }
	}

  .column-price {
    margin-right: 50px;
  }

  .column-num {
    margin-right: 20px;
  }

	.price {
		color: #5F5C5C;
	}

	&__quantity {
		min-width: 40px;

		text-align: center;
	}

	&__btn-quantity {
		width: 32px;
		height: 32px;

		cursor: pointer;
		transition: background-color 0.3s;

		border: 1px solid #F2F2F2;
		border-radius: 8px;
		background-color: #F3EAEA;

		&:hover {
			transition: background-color 0.3s;

			background-color: #F0B4AE;
		}

		&:active {
			transition: background-color 0.3s;

			background-color: darken($color: #F0B4AE, $amount: 20%);
		}
	}

	// .cancel {
	// 	color: $hover;
	// 	border: 1px solid $hover;
	// 	background-color: transparent;

	// 	&:hover {
	// 		transition: background-color 0.3s;

	// 		background-color: #F0B4AE;
	// 	}

	// 	&:active {
	// 		transition: background-color 0.3s color 0.3s;

	// 		color: $default;
	// 		background-color: darken($color: #F0B4AE, $amount: 20%);
	// 	}
	// }

	.clear {
		color: #FFF;
		border: 0 solid $hover;
		background-color: $active;

		&:hover {
			transition: background-color 0.3s;

			background-color: #F0B4AE;
		}

		&:active {
			transition: background-color 0.3s color 0.3s;

			color: $default;
			background-color: darken($color: #F0B4AE, $amount: 20%);
		}
	}
}