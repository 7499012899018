@import '../../../scss/vars.scss';
@import '../../../scss/mixins.scss';

.blog {
	position: relative;

	padding-top: 70px;

	&::before {
		top: 0;
		left: 0;

		width: 1px;
		height: 100%;

		content: '';

		color: rgb(205 186 170 / 50%);
	}

	&__content {
		height: 100%;
		padding: 0 0 70px;

		@include _1200 {
			flex-direction: column;
		}
	}

	&__row {
		width: 100%;
	}
}

.blog-img {
	margin-right: 20px;

	border-radius: 15px;

	@include _1200 {
		max-height: 500px;
		margin-right: 0;
		margin-bottom: 20px;
		object-fit: cover;
		object-position: top center;
	}
}

.blog-row {
	width: 100%;

	color: $default;
	border: 0.5px solid $hover;
	border-radius: 15px;
	background-color: #FFF;

	&__content {
		min-width: 400px;

		@include _767 {
			min-width: 100%;
		}
	}

	&__element {
		padding: 5px;

		color: $default;
		border-radius: 15px;
		box-shadow: rgb(0 0 0 / 2%) 0 1px 3px 0, rgb(27 31 35 / 15%) 0 0 0 1px;

		font-size: 18px;

		&:hover {
			transition: box-shadow 0.3s;

			box-shadow: rgb(0 0 0 / 24%) 0 3px 8px;
		}

		@extend %libreBaskervilleItalic-400;

		@include _767 {
			align-items: center;
			flex-direction: column;

			padding: 10px;

			box-shadow: rgb(0 0 0 / 2%) 0 1px 3px 0, rgb(27 31 35 / 15%) 0 0 0 1px;
		}
	}

	&__headline {
		margin-bottom: 30px;
	}

	&__title {
		margin-bottom: 20px;

		font-size: 24px;
	}

	&__text {
		flex-grow: 1;

		font-size: 16px;
	}

	&__column-left {
		width: calc(50% - 20px);
		height: auto;
		min-height: 100%;
	}

	&__img {
		width: calc(40%);
		max-width: 260px;
		max-height: 150px;
		margin-right: 20px;

		border-radius: 10px;
		object-fit: cover;

		@include _767 {
			margin-right: 0;
			margin-bottom: 20px;
		}
	}
}