.list {
	display: flex;
	align-items: center;
	justify-content: flex-start;

	white-space: nowrap;

	color: #342314;

	font-size: 14px;
}

.link {
	text-decoration: underline;

	color: #342314;
}

.item {
	position: relative;

	display: flex;

	&-accent {
		display: inline;

		color: #888C91;
	}

	&::before {
		content: "/";

		color: #342314;
	}

	&:not(:first-child) {
		margin-left: 5px;

		&::before {
			margin-right: 5px;
			margin-left: 5px;
		}
	}

	&:first-child {
		&::before {
			content: "";
		}
	}

	&:last-child {
		color: #888C91;

		.link {
			cursor: default;
			text-decoration: none;
		}
	}
}

@media screen and (width <= 767px){
	.list {
		overflow-x: scroll;
	}
}
