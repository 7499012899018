@import '../../scss/mixins.scss';

.base-set {
	width: 100%;

	background-repeat: no-repeat;
	background-position: top center;
	background-size: cover;
}

.set-1 {
	padding-bottom: 50px;

	background-image: linear-gradient(rgb(255 255 255 / 30%), rgb(255 255 255 / 30%)), url("../../assets/img/home/subs.webp");
}

.set-2 {
	padding: 150px 0 50px;

	background-image: linear-gradient(rgb(255 255 255 / 30%), rgb(255 255 255 / 30%)), url("../../assets/img/home/subs-2.webp");
}

.set-4 {
	padding: 150px 0 50px;

	background-image: linear-gradient(rgb(255 255 255 / 30%), rgb(255 255 255 / 30%)), url("../../assets/img/home/subs-3.webp");
}

.set-3 {
	width: 100%;
	margin-bottom: -225px;
	padding-bottom: 100px;

	background-color: #BFC1BF;

	@include _1200 {
		display: none;
	}
}

.home {
	&__banner {
		width: 100%;
		margin-bottom: 100px;
		object-fit: cover;

	}

	&__banner-clean {
		width: 100%;
		object-fit: cover;

		@include _767 {
			min-height: 400px;
		}
	}
}