.top-products__text {
	display: none;
}

.top-products {
	&__item {
		&:hover {
			.top-products__cart  {
				transition: all 0.3s;
			}
		}
	}

	&__title {
		margin-bottom: 10px;
	}

	&__desc {
		display: -webkit-box;
		overflow: hidden;

		width: 100%;
		max-width: 230px;

		text-align: center;
		-webkit-line-clamp: 3;
		-webkit-box-orient: vertical;
	}

	&__price-opt {
		display: flex;
	}

	&__price-name {
		font-size: 20px;
	}

	&__code {
		color: #8E0303;

		font-size: 12px
	}

	&__cart {
		position: absolute;
		right: 10px;
		bottom: 10px;

		display: flex;
		align-items: center;
		justify-content: center;

		width: 30px;
		height: 30px;
		padding: 10px;

		cursor: pointer;
		transition: all 0.3s;

		border: 1px solid #FFF;
		border-radius: 15px;
		background-color: #FFF;
		box-shadow: rgb(0 0 0 / 2%) 0 1px 3px 0, rgb(27 31 35 / 15%) 0 0 0 1px;
		object-fit: cover;

		&:hover {
			transition: background-color 0.3s border 0.3s;

			border: 1px solid #8E0303;
			background-color: rgb(247 238 238);
		}
	}

	&__info {
		margin-bottom: 5px;

		color: #8E0303;

		font-size: 14px;
	}
}