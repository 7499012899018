@import '../../../scss/vars.scss';
@import '../../../scss/mixins.scss';

.reviews {
	position: relative;

	margin-bottom: 150px;

	&::before {
		top: 0;
		left: 0;

		width: 1px;
		height: 100%;

		content: '';

		color: rgb(205 186 170 / 50%);
	}

	&__desc {
		display: flex;
		align-items: baseline;

		width: fit-content;
		margin-bottom: 20px;
		margin-left: -20px;
		padding: 5px 10px;

		text-transform: uppercase;

		color: #CDBAAA;

		font-size: 14px;
		font-weight: 400;

		@media screen and (width <= 767px) {
			display: flex;
			justify-content: center;

			width: 100%;
			margin-left: 0;
		}
	}

	&__inner {
		display: flex;
		flex-direction: column;
	}

	&__subtitle {
		text-align: left;
	}

	&__row {
		justify-content: space-between;

		margin-bottom: 40px;

		.reviews__element {
			width: calc(30%);

			&:not(:last-child) {
				margin-right: 20px;
			}

			@include _767 {
				width: 100%;
				margin-bottom: 20px;
			}
		}

		@include _767 {
			flex-direction: column;

			margin-bottom: 0;
		}
	}
}

.element {
	&__headline {
		margin-bottom: 20px;
	}

	&__fullname {
		font-size: 18px;
		font-weight: bold;
	}

	&__date {
		font-style: italic;
	}

	&__img {
		width: 80px;
		height: 80px;
		margin-right: 10px;

		border-radius: 50%;
	}
}