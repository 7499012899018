@import '../../../scss/vars.scss';
@import '../../../scss/mixins.scss';

.headline {
	position: relative;

	margin-bottom: 150px;

	&::before {
		top: 0;
		left: 0;

		width: 1px;
		height: 100%;

		content: '';

		color: rgb(205 186 170 / 50%);
	}


	&__desc {
		align-items: baseline;

		padding: 5px 10px;
	}

	@include _1200 {
		margin-bottom: 100px;
	}

	@include _767 {
		margin-bottom: 50px;
	}

	@include _320 {
		margin-bottom: 30px;
	}
}

@media screen and (width <= 1024px) {
	.headline {
		.line {
			display: none;
		}
	}
}

@media screen and (width <= 767px) {
	.headline {
		&__desc {
			left: -5px;
		}
	}
}

@media screen and (width <= 468px) {
	.headline {
		&__desc {
			display: flex;
			justify-content: center;

			width: 100%;
		}
	}
}


