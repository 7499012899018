@import '../../../scss/vars.scss';
@import '../../../scss/mixins.scss';

.video {
  padding: 70px 0px 0px;

  @include _320 {
    padding: 50px 0 0; 
  }

  &__item {
    iframe {
      padding: 0;
      border-radius: 15px;
    }
  }

  .swiper-wrapper {
    padding: 0;
  }

  .swiper-slide {

    @include _1024 {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
  }

  .swiper-button-prev,
  .swiper-button-next {
    position: relative;
    height: 50px;
    width: 50px;
    background-color: #fff;
    border-radius: 50%;
    z-index: 10;
    border: 0.5px solid #8E0303;
    transition: background-color 0.3s;

    &:before {
      position: absolute;
      display: block;
      content: url('../../../assets/icons/category/arrow-b.svg');
    }

    @include _1024 {
      display: none;
    }

    &:hover {
      background-color: darken($color: #fff, $amount: 5%);
      transition: background-color 0.3s;
    }
  }

  .swiper-button-prev {
    bottom: 4px;
    left: 88%;

    &:before {
      right: 20px;
      bottom: 13px;
      transform: rotate(-180deg);
    }
  }

  .swiper-button-next {
    bottom: 32px;
    left: 95%;

    &:before {
      right: 16px;
      bottom: 6px;
    }
  }

  &__title {
    padding: 10px 0;
    font-size: 22px;
    font-weight: bold;
    color: $default;
  }

  &__text {
    text-align: start;
    font-size: 20px;
    @extend %libreBaskerville-400;
  }

  &__text-accent {
    padding: 0 5px;
    color: $hover;
  }

  &__text-desc {
    color: $default;
  }

  &__meta-text {
    font-size: 24px;
    font-weight: 400;
  }
}

@media screen and (max-width: 1024px) {
  .video {

    &__text {
      margin-bottom: 50px;
      font-size: 24px;
    }

    &__meta-text {
      font-size: 14px;
    }

    &__content {
      & a:last-child {
        margin-right: 35px;
      }
    }

    &__link {
      width: 157px;
      height: 157px;
      margin-right: 20px;

      &:hover {
        box-shadow: none;
      }
    }

    &__card-desc {
      font-size: 18px;
    }
  }
}

@media screen and (max-width: 468px) {
  .video {

    &__text {
      font-size: 18px;
      margin-bottom: 20px;
    }

    &__content {
      position: relative;
      flex-direction: column;
      margin-right: 0;

      & a {
        width: 100%;
        margin-bottom: 20px;
      }
    }

    &__link {
      width: 100%;
      height: 44px;
      padding: 12px;
      border-radius: 0px 18px 18px 18px;
    }
  }
}

  // .swiper-slide-active {
  //   @include _380 {
  //     width: 60%!important; 
  //   }
  // }

