@import '../../scss/mixins.scss';

.contacts-page {
	padding-top: 30px;

	&__title {
		display: flex;
		justify-content: center;

		padding-top: 50px;

		font-size: 48px;
	}

	&__content {
		display: flex;
		align-items: center;
		justify-content: center;

		margin: 30px 0;

    @include _1024 {
      flex-direction: column-reverse;
    }

	}
  
	&__image {
		max-width: 600px;
		margin-left: 40px;

		border-radius: 25px;
		object-fit: cover;

    @include _1024 {
      margin-left: 0;
      margin-bottom: 20px;
    }

    @include _640 {
      max-width: 250px;
    }
	}

	&__subtitle {
		max-width: 500px;

		text-align: center;

		color: #564848;

		font-size: 24px;
	}

	&__row {
		display: flex;
		align-items: center;
		flex-direction: column;
	}

	&__partners {
		display: flex;
		align-items: center;
		justify-content: space-between;

		width: 100%;
		padding: 30px 0;
	}

	&__items {
		display: flex;
		overflow-x: hidden;
		flex-wrap: wrap;
		justify-content: center;

		max-height: 600px;
		margin-right: 20px;
		padding-left: 40px;

    @include _640 {
      padding-left: 0;
      margin-right: 0;
    }
	}

	&__item {
		position: relative;

		width: 100%;
		min-width: 400px;
		max-width: 470px;
		margin-right: 10px;
		margin-bottom: 5px;
		padding: 10px;

		cursor: pointer;

		color: #131938;
		border: 0.5px solid #8E0303;
		border-radius: 15px;
		background-color: #FFF;

    @include _640 {
      min-width: 250px;
      margin-right: 0;
    }

		&:hover {
			transition: background-color 0.3s;

			background-color: #F7F1F1;
		}

		&.active {
			background-color: #F1EAEA;

			&::before {
				position: absolute;
				top: 0;
				bottom: 0;
				left: -35px;

				width: 20px;
				height: 20px;
				margin: auto;

				content: "";

				border: 3px solid #7E0000;
				border-radius: 50%;
				background-color: #FFF;
			}
		}
	}

	&__caffee {
		font-size: 20px;
	}
}
