.fab {
	position: fixed;
	z-index: 100;
	right: 15px;
	bottom: 15px;

	width: 56px;
	margin-left: -25px;
}

.fab:hover .fab-buttons {
	visibility: visible;

	opacity: 1;
}

.fab:hover .fab-buttons__link {
	transform: scaleY(1) scaleX(1) translateY(-16px) translateX(0);
}

.fab-action-button {
	position: absolute;
	bottom: 0;

	display: block;

	width: 56px;
	height: 56px;

	transition: background-color 0.3s;

	animation: pulse 2s infinite;

	border-radius: 50%;
	background-color: #29B6F6;
	box-shadow: 0 2px 2px 0 rgb(0 0 0 / 14%), 0 1px 5px 0 rgb(0 0 0 / 12%), 0 3px 1px -2px rgb(0 0 0 / 20%);

	&:hover {
		transition: background-color 0.3s;
		animation: none;

		background-color: darken($color: #29B6F6, $amount: 15%);
	}
}

.fab-buttons {
	position: absolute;
	right: 0;
	bottom: 50px;
	left: 0;

	visibility: hidden;

	margin: 0;
	padding: 0;

	list-style: none;

	transition: 0.2s;

	opacity: 0;
}

.fab-action-button__icon {
	display: inline-block;

	width: 56px;
	height: 56px;

	background: url("data:image/svg+xml;base64,PHN2ZyBmaWxsPSIjZmZmZmZmIiBoZWlnaHQ9IjI0IiB2aWV3Qm94PSIwIDAgMjQgMjQiIHdpZHRoPSIyNCIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KICAgIDxwYXRoIGQ9Ik0wIDBoMjR2MjRIMHoiIGZpbGw9Im5vbmUiLz4KICAgIDxwYXRoIGQ9Ik0xOCAxNi4wOGMtLjc2IDAtMS40NC4zLTEuOTYuNzdMOC45MSAxMi43Yy4wNS0uMjMuMDktLjQ2LjA5LS43cy0uMDQtLjQ3LS4wOS0uN2w3LjA1LTQuMTFjLjU0LjUgMS4yNS44MSAyLjA0LjgxIDEuNjYgMCAzLTEuMzQgMy0zcy0xLjM0LTMtMy0zLTMgMS4zNC0zIDNjMCAuMjQuMDQuNDcuMDkuN0w4LjA0IDkuODFDNy41IDkuMzEgNi43OSA5IDYgOWMtMS42NiAwLTMgMS4zNC0zIDNzMS4zNCAzIDMgM2MuNzkgMCAxLjUtLjMxIDIuMDQtLjgxbDcuMTIgNC4xNmMtLjA1LjIxLS4wOC40My0uMDguNjUgMCAxLjYxIDEuMzEgMi45MiAyLjkyIDIuOTIgMS42MSAwIDIuOTItMS4zMSAyLjkyLTIuOTJzLTEuMzEtMi45Mi0yLjkyLTIuOTJ6Ii8+Cjwvc3ZnPg==") center no-repeat;
}

.fab-buttons__item {
	display: block;

	margin: 12px 0;

	text-align: center;
}

.fab-buttons__link {
	display: inline-flex;
	align-items: center;
	justify-content: center;

	width: 42px;
	height: 42px;

	transition: .3s;
	transition: .3s;
	transition: .3s;
	transition: .3s;
	transform: scaleY(0.5) scaleX(0.5) translateY(0) translateX(0);
	text-decoration: none;

	border-radius: 50%;
	background-color: #FFF;

	.twitter {
		display: inline-flex;
		align-items: center;
		justify-content: center;

		width: 40px;
		height: 40px;

		border-radius: 50%;
		background-color: #2C397C;
	}
}

.fab-buttons__link.player {
	background-color: transparent;
}

[data-tooltip]::before {
	position: absolute;
	top: 50%;
	right: 100%;

	visibility: hidden;

	margin-top: -11px;
	margin-right: 12px;
	padding: 4px 7px;

	content: attr(data-tooltip);
	transition: all .3s cubic-bezier(.25, .8, .25, 1);
	transform: scale(0);
	transform-origin: top right;
	white-space: nowrap;
	text-decoration: none;

	opacity: 0;
	color: #FFF;
	border-radius: 2px;
	background: #585858;

	font-size: 12px;
	font-weight: 600;
}

[data-tooltip]:hover::before {
	visibility: visible;

	transform: scale(1);
	transform-origin: right center 0;

	opacity: 1;
}

.icon-material {
	display: inline-block;

	width: 38px;
	height: 38px;
}

.icon-material_li {
	background: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iaXNvLTg4NTktMSI/Pgo8IS0tIEdlbmVyYXRvcjogQWRvYmUgSWxsdXN0cmF0b3IgMTYuMC4wLCBTVkcgRXhwb3J0IFBsdWctSW4gLiBTVkcgVmVyc2lvbjogNi4wMCBCdWlsZCAwKSAgLS0+CjwhRE9DVFlQRSBzdmcgUFVCTElDICItLy9XM0MvL0RURCBTVkcgMS4xLy9FTiIgImh0dHA6Ly93d3cudzMub3JnL0dyYXBoaWNzL1NWRy8xLjEvRFREL3N2ZzExLmR0ZCI+CjxzdmcgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgdmVyc2lvbj0iMS4xIiBpZD0iQ2FwYV8xIiB4PSIwcHgiIHk9IjBweCIgd2lkdGg9IjE2cHgiIGhlaWdodD0iMTZweCIgdmlld0JveD0iMCAwIDUxMCA1MTAiIHN0eWxlPSJlbmFibGUtYmFja2dyb3VuZDpuZXcgMCAwIDUxMCA1MTA7IiB4bWw6c3BhY2U9InByZXNlcnZlIj4KPGc+Cgk8ZyBpZD0icG9zdC1saW5rZWRpbiI+CgkJPHBhdGggZD0iTTQ1OSwwSDUxQzIyLjk1LDAsMCwyMi45NSwwLDUxdjQwOGMwLDI4LjA1LDIyLjk1LDUxLDUxLDUxaDQwOGMyOC4wNSwwLDUxLTIyLjk1LDUxLTUxVjUxQzUxMCwyMi45NSw0ODcuMDUsMCw0NTksMHogICAgIE0xNTMsNDMzLjVINzYuNVYyMDRIMTUzVjQzMy41eiBNMTE0Ljc1LDE2MC42NWMtMjUuNSwwLTQ1LjktMjAuNC00NS45LTQ1LjlzMjAuNC00NS45LDQ1LjktNDUuOXM0NS45LDIwLjQsNDUuOSw0NS45ICAgIFMxNDAuMjUsMTYwLjY1LDExNC43NSwxNjAuNjV6IE00MzMuNSw0MzMuNUgzNTdWMjk4LjM1YzAtMjAuMzk5LTE3Ljg1LTM4LjI1LTM4LjI1LTM4LjI1cy0zOC4yNSwxNy44NTEtMzguMjUsMzguMjVWNDMzLjVIMjA0ICAgIFYyMDRoNzYuNXYzMC42YzEyLjc1LTIwLjQsNDAuOC0zNS43LDYzLjc1LTM1LjdjNDguNDUsMCw4OS4yNSw0MC44LDg5LjI1LDg5LjI1VjQzMy41eiIgZmlsbD0iIzc1NzU3NSIvPgoJPC9nPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+Cjwvc3ZnPgo=") center no-repeat;
}

.player {
	&__btn {
		padding: 0;

		border: 0;
		background-color: transparent;
	}

	&__icon {
		width: 40px;
		height: 40px;
		margin: auto;

		border-radius: 50%;
	}
}

@keyframes pulse {
	0% {
		box-shadow: 0 0 0 0 rgb(204 169 44 / 40%);
	}

	70% {
		box-shadow: 0 0 0 15px rgb(204 169 44 / 0%);
	}

	100% {
		box-shadow: 0 0 0 0 rgb(204 169 44 / 0%);
	}
}

@keyframes pulse {
	0% {
		box-shadow: 0 0 0 0 rgb(204 169 44 / 40%);
		box-shadow: 0 0 0 0 rgb(204 169 44 / 40%);
	}

	70% {
		box-shadow: 0 0 0 15px rgb(204 169 44 / 0%);
		box-shadow: 0 0 0 15px rgb(204 169 44 / 0%);
	}

	100% {
		box-shadow: 0 0 0 0 rgb(204 169 44 / 0%);
		box-shadow: 0 0 0 0 rgb(204 169 44 / 0%);
	}
}