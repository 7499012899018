//* Globals *
@import "./reset";
@import "./vars";
@import "./fonts";
@import "./mixins";
@import "./normalize";
@import "./global";
@import "./libs";

//* Plagins *
@import "../../node_modules/macro-css/index.min.css";

//* Elements *
@import "../components/elements/closedButton/closedButton";
@import "../components/elements/menu/menu";
@import "../components/elements/locales/locales.scss";

//* Modules *
@import "../components/modules/footer/footer.scss";
@import "../components/modules/header/header";
@import "../components/modules/header/headerMenuPopup/headerMenuPopup";

@import "../components/sections/slider/Slider.scss";


