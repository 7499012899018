@import '../../../scss/vars.scss';
@import '../../../scss/mixins.scss';

.about-products {
	position: relative;

	margin-bottom: 150px;

  @include _767 {
		margin-bottom: 80px;
	}

	&::before {
		top: 0;
		left: 0;

		width: 1px;
		height: 100%;

		content: '';

		color: rgb(205 186 170 / 50%);
	}

	&__btn {
		.contacts-btn {
			display: inline-flex
		}

		&.ground {
			@include _767 {
				display: none;
			}
		}
	}

	.about-products__btn-mobile {
		display: none;
	}

	.catalog-btn,
	.about-products__btn-mobile {
		@include _767 {
			display: inline-flex;

			width: 100%;
      min-width: 200px;
			margin-top: 20px;
			margin-right: 0!important;

			.atuin-btn {
				width: 100%!important;
			}
		}
	}

	.single-doses {
		@include _767 {
			display: flex;
			flex-direction: column;
		}
	}

	&__desc {
		display: flex;
		align-items: baseline;

		width: fit-content;
		margin-bottom: 20px;
		margin-left: -20px;
		padding: 5px 10px;

		text-transform: uppercase;

		color: #CDBAAA;
		background-color: transparent;

		font-size: 14px;
		font-weight: 400;

		@media screen and (width <= 767px) {
			display: flex;
			justify-content: center;

			width: 100%;
			margin-left: 0;
		}
	}

	&__inner {
		display: flex;
		flex-direction: column;
	}

	&__subtitle {
		text-align: left;
	}

	&__row {
		width: 100%;

		&.center {
			margin-bottom: 40px;
      display: inline-flex;
      align-items: flex-start;

			@include _1200 {
				flex-direction: column;

				margin-bottom: 0;
			}

      .column-right {
        margin-right: auto;
      }
		}
    
		@include _1024 {
			position: relative;
		}
	}

	&__label,
	&__label--svg {
		position: absolute;

		display: none;

		@include _1024 {
			right: 20px;

			display: inline-flex;
		}
	}

	&__label--svg {
		@include _1024 {
			top: -5px;
		}
	}

	&__label {
		@include _1024 {
			top: -3px;
		}
	}
}

.about-row {
	width: 100%;

	color: $default;
	border: 0.5px solid $hover;
	border-radius: 15px;
	background-color: #FFF;

	&__label {
		display: none;

		@include _1024 {
			position: absolute;
			top: 100px;
			right: 30px;

			display: inline-flex;

			width: 40px;
			height: 60px;

			background-color: brown;

		}
	}

	&__block-beans {
		.about-row__text {
			padding-bottom: 20px;

			@include _1024 {
				padding: 0;

				&.mobile {
					visibility: hidden;

					height: 0;
				}
			}
		}
	}

	&__title {
		margin-bottom: 20px;

		font-size: 24px;
	}

	&__text {
		font-size: 16px;

		@include _1024 {
			display: -webkit-box;
			overflow: hidden;

			padding: 0;
			-webkit-line-clamp: 3;
			-webkit-box-orient: vertical;
		}
	}

	&__column-left {
		width: calc(90% - 20px);
		height: auto;
		min-height: 100%;
		margin-right: 20px;

		@include _1200 {
			width: 100%;
		}

		@include _767 {
			margin-right: 0;
			margin-bottom: 0;

			.contacts-btn {
				display: inline-flex;

				width: 100%;
				margin-right: 0!important;

				.atuin-btn {
					width: 100%!important;
				}
			}
		}
	}

	&__headline {
		@include _767 {
			.about-products__btn {
				display: none;
			}
		}
	}

	&__column-right {
		width: 100%;
		min-height: 100%;
		margin-right: 0;
	}

	&__inner {
		align-items: baseline;
		justify-content: space-between;
	}

	&__img {
		float: right;

		width: 32%;

		border-radius: 10px;

		@include _1200 {
			display: none;
		}
	}

	&__img-moka {
		float: right;

		width: 35%;

		border-radius: 10px;
		object-fit: cover;

		@include _1200 {
			display: none;
		}
	}

	&__img--big {
		width: 20%;
		max-height: 250px;
		margin-right: 0;

		border-radius: 10px;

		@include _1200 {
			display: none;
		}
	}

	&__img-green {
		width: 20%;
	}

	&__column {
		position: relative;

		padding: 20px;
	}

	&__column-inner {
		width: calc(50% - 20px);

		@include _1200 {
			width: 100%;
		}

    .ground-coffe {
      margin-bottom: 40px;
    }
	}

	@extend %libreBaskervilleItalic-400;
}

.column-right {
	height: 100%;

	&__img {
		float: bottom;

		width: 55%;
		max-height: 250px;
		margin: 0 auto;

		border-radius: 10px;
		object-fit: cover;

		@include _1200 {
			display: none;
		}
	}

	@include _1200 {
		margin-bottom: 40px;
	}
}

.label-mobile {
  filter: invert(1);
}