@import '../../../scss/mixins.scss';

.top-products {
	position: relative;

	width: 100%;
	padding: 70px 20px 20px;

	&__description {
		display: flex;

		margin-bottom: 20px;
	}

	&__price {
		@include _767 {
			margin-left: 0;
		}
	}

	.swiper-wrapper {
		padding: 0 0 40px;

		@include _767 {
			padding: 0 0 35px;
		}
	}

	.swiper-button-prev,
	.swiper-button-next {
		position: relative;
		z-index: 10;

		width: 50px;
		height: 50px;

		transition: background-color 0.3s;

		border: 0.5px solid #8E0303;
		border-radius: 50%;
		background-color: #FFF;

		&::before {
			position: absolute;

			display: block;

			content: url('../../../assets/icons/category/arrow-b.svg');
		}

		&:hover {
			transition: background-color 0.3s;

			background-color: darken($color: #FFF, $amount: 5%);
		}

		@include _1024 {
			display: none;
		}
	}

	.swiper-button-prev {
		bottom: 4px;
		left: 88%;

		&::before {
			right: 20px;
			bottom: 13px;

			transform: rotate(-180deg);
		}

		@include _1200 {
			left: 84%;
		}
	}

	.swiper-button-next {
		bottom: 32px;
		left: 95%;

		&::before {
			right: 16px;
			bottom: 6px;
		}

		@include _1200 {
			left: 92%;
		}
	}

	&__item {
		padding: 14px;

		transition: box-shadow 0.3s, background-color 0.3s;

		border: 1px solid #8E0303;
		border-radius: 25px 25px 25px 0;
		background-color: #FFF;

		&:hover {
			transition: box-shadow 0.3s, background-color 0.3s;

			background-color: rgb(255 249 249);
			box-shadow: rgb(0 0 0 / 24%) 0 3px 8px;
		}
	}

	&__image {
		position: relative;

		display: flex;
		align-items: center;
		justify-content: center;

		width: 100%;
		max-height: 200px;
		margin: 0;

		border-radius: 25px 25px 0 0;
		background-color: #FFF;
	}

	&__title {
		display: flex;
		flex-direction: column;

		margin-top: 12px;
		margin-bottom: 12px;

		text-transform: none;

		font-style: italic;
	}

	&__weight {
		position: absolute;
		top: 12px;
		right: 12px;
	}

	&__price {
		font-size: 38px;
		font-weight: 400;
	}
}

@media screen and (width <= 1024px) {
	.top-products {
		padding-bottom: 70px;
	}
}

@media screen and (width <= 767px) {
	.top-products {
		padding-bottom: 0;

		&__description {
			margin-bottom: 10px;
		}
	}
}

.swiper-pagination {
	.swiper-pagination-bullet-active {
		background: #8E0303!important;
	}
}
