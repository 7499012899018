@import '../../../scss/mixins';

.button-prev,
.button-next {
	position: relative;
	z-index: 10;

	width: 50px;
	height: 50px;

	cursor: pointer;
	transition: background-color 0.3s;

	border: 0.5px solid #8E0303;
	border-radius: 50%;
	background-color: #FFF;

	&::before {
		position: absolute;

		display: block;

		content: url('../../../assets/icons/category/arrow-b.svg');
	}

	&:hover {
		transition: background-color 0.3s;

		background-color: darken($color: #FFF, $amount: 5%);
	}
}

.button-num {
	position: relative;
	z-index: 10;

	display: flex;
	align-items: center;
	justify-content: center;

	width: 50px;
	height: 50px;
	margin-right: 5px;
	padding-top: 2px;

	cursor: pointer;
	transition: background-color 0.3s;

	color: #8E0303;
	border: 0.5px solid #8E0303;
	border-radius: 50%;
	background-color: #FFF;

	font-size: 20px;

	&:hover {
		transition: background-color 0.3s;

		background-color: darken($color: #FFF, $amount: 5%);
	}

	&.active {
		transition: background-color 0.3s;

		background-color: darken($color: #FFF, $amount: 5%);
	}
}

.button-prev {
	margin-right: 5px;

	&::before {
		right: 20px;
		bottom: 14px;

		transform: rotate(-180deg);
	}

	&.disable {
		pointer-events: none;

		opacity: 0.5;
	}
}

.button-next {
	&::before {
		right: 16px;
		bottom: 8px;
	}

	&.disable {
		pointer-events: none;

		opacity: 0.5;
	}
}