@mixin _1400 {
  @media (max-width: 1440px) {
    @content;
  }
}

@mixin _1300 {
  @media (max-width: 1300px) {
    @content;
  }
}

@mixin _1200 {
  @media (max-width: 1200px) {
    @content;
  }
}

@mixin _1024 {
  @media (max-width: 1024px) {
    @content;
  }
}

@mixin _768 {
  @media (max-width: 768px) {
    @content;
  }
}

@mixin _767 {
  @media (max-width: 767px) {
    @content;
  }
}

@mixin _640 {
  @media (max-width: 640px) {
    @content;
  }
}

@mixin _500 {
  @media (max-width: 500px) {
    @content;
  }
}

@mixin _380 {
  @media (max-width: 380px) {
    @content;
  }
}

@mixin _320 {
  @media (max-width: 320px) {
    @content;
  }
}
