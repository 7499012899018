@import '../../../scss/mixins.scss';
@import '../../../scss/vars.scss';

.sub-footer {
	color: #CDBAAA;

	font-size: 10px;

	@include _1024 {
		padding-top: 10px;
	}
}

.footer {
	position: relative;
	z-index: 20;

	width: 100%;
	margin-bottom: 15px;

	&__bottom {
		display: flex;
		align-items: flex-end;
		flex-direction: column;

		height: 210px;
		padding: 40px 110px 50px;

		border-radius: 40px;
		background-color: #FFF;
		box-shadow: rgb(0 0 0 / 2%) 0 1px 3px 0, rgb(27 31 35 / 15%) 0 0 0 1px;
  
		@include _1200 {
			height: 100%;
			padding: 40px;
		}
	}

	&__box {
		@include _380 {
			display: flex;
			flex-direction: column;
		}
	}

	&__logo {
		display: flex;
		align-items: center;
		flex-direction: column;

		@include _1024 {
			width: calc(100%);
		}

		@include _380 {
			margin-bottom: 20px;
		}
	}

	&__nav {
		@include _1024 {
			flex-direction: column;
		}
	}

	&__bottom-meta {
		display: flex;
		align-items: center;
		justify-content: space-between;

		width: 100%;
		margin-bottom: 10px;
	}

	&__copy {
		align-items: flex-start;

		width: 100%;

		& a,
		& p {
			color: #CDBAAA;

			font-size: 14px;
		}
	}

	.socials {
		&__item {
			&:not(:last-child) {
				margin-right: 10px;
			}
		}

		&__link {
			display: flex;
			align-items: center;
			justify-content: center;

			width: 34px;
			height: 34px;

			border-radius: 50%;

			&.twitter {
				background-color: #131938;
			}

			&:hover {
				&.telegram,
				&.facebook,
				&.instagram {
					transition: background-color 0.3s;

					background-color: darken($color: #FFF, $amount: 30%);
				}

				&.twitter {
					.socials__ico path {
						fill: darken($color: #FFF, $amount: 30%);

						transition: fill 0.3s;
					}
				}
			}
		}

		@include _1024 {
			display: inline-flex;
		}
	}

	&__headline {
		color: #131938;

		font-size: 18px;
		font-weight: bold;
	}

	&__link {
		transition: color 0.3s;

		color: #2B304F;

		font-size: 16px;
		font-weight: normal;

		&:hover {
			transition: color 0.3s;

			color: lighten($color: #2B304F, $amount: 30%);
		}
	}
}

.icon-instagram {
	&::before {
		transition: color 0.3s;

		color: #FFF;

		font-size: 26px;
	}

	&:hover {
		&::before {
			transition: color 0.3s;

			color: darken($color: #FFF, $amount: 30%);
		}
	}
}


@media screen and (width <= 1024px) {
	.footer {
		&__inner {
			flex-direction: column;
		}

		&__item {
			width: 100%;

			& ul li {
				width: 40%;
			}
		}

		&__social {
			flex: 1;
			justify-content: space-evenly;
		}

		&__copy {
			justify-content: space-between;

			& a {
				margin-left: auto;
			}
		}
	}
}

@media screen and (width <= 767px) {
	.footer {
		&__item ul li {
			width: 100%;
		}

		&__bottom-meta {
			align-items: flex-start;
			flex-direction: column;
		}

		&__cicr {
			margin-bottom: 24px;
		}

		&__copy {
			align-items: flex-start;
			flex-direction: column;

			& p {
				margin-bottom: 10px;
			}

			& a {
				margin-bottom: 10px;
				margin-left: 0;
			}
		}

	}
}