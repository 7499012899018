@import '../../../scss/mixins';


.poppup {
	position: fixed;
	z-index: 1000;
	inset: 0;

	overflow-y: auto;

	background-color: rgb(0 0 0 / 80%);

	&-inner {
		position: absolute;
		z-index: 10;
		top: 50%;
		left: 50%;

		display: flex;
		align-items: center;
		flex-direction: column;
		justify-content: center;

		min-width: 600px;
		max-width: 1070px;
		height: 100%;
		min-height: 300px;
		max-height: 500px;
		padding: 50px 80px;

		transform: translate(-50%, -50%);
		text-align: center;

		border-radius: 33px;
		background-color: #FFF;
		box-shadow: 0 2px 15px rgb(0 0 0 / 15%);

		.closed-btn {
			display: flex;
			justify-content: flex-end;

			width: 100%;
    
			&__line {
				margin-right: 0;
			}

			@include _767 {
				top: 10px;
				right: 10px;
			}
		}

		@include _767 {
			min-width: 95%;
			padding: 20px 30px;
		}
	}

	.cart {
		&__img {
			@include _767 {
				margin-right: 5px;
				margin-left: 0;
			}
		}
	}

	.cart-item {
		&__controls {
			@include _767 {
				padding: 0 10px 0 5px;
			}
		}

		@include _767 {
			margin-bottom: 30px;
		}
	}
}
