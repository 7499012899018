@import '../../scss/mixins.scss';

.card_details-page {
	padding-top: 30px;

	&__title {
		display: flex;
		justify-content: center;

		padding-top: 50px;

		font-size: 48px;
	}

	&__title-img {
		width: 100%;
		max-height: 300px;
		margin: 0 auto;
		object-fit: cover;
	}
}

.catalog-page {
	padding-top: 30px;

	&__title {
		display: flex;
		justify-content: center;

		padding-top: 50px;

		font-size: 48px;
	}

	&__title-img {
		width: 100%;
		max-height: 300px;
		margin: 0 auto;
		object-fit: cover;
	}
}

.catalog {
	&__nav {
		min-width: 200px;
		max-width: 200px;
		margin-right: 20px;

    @include _640 {
      margin-right: 0;
      margin-bottom: 20px;
    }
	}

	&__category {
		width: 100%;
	}

	&__block {
		position: relative;

		width: 100%;
		margin: 50px 0;

    @include _640 {
      flex-direction: column;
    }
	}

	&__items {
		display: flex;
		flex: 1;
		flex-wrap: wrap;
		justify-content: flex-start;

		min-width: 100%;

    @include _640 {
      justify-content: center;
    }
	}

	&__title {
		text-transform: uppercase;

		font-weight: 500;
	}

	&__category-item {
		padding: 3px 0 0 5px;

		cursor: pointer;

		border-radius: 5px;

		&.active {
			background-color: #F2E9E9;
		}
	}

	&__content {
		display: flex;
		align-items: center;
		flex-direction: column;

		width: 100%;
		margin-left: 50px;

    @include _640 {
      margin-left: 0;
    }
	}
}

.card-details {
	&__content {
		margin: 50px 0;
	}
}