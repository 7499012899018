.container-page {
	padding-top: 30px;

	&__title {
		display: flex;
		justify-content: center;

		padding-top: 50px;

		font-size: 48px;
	}

	&__title-img {
		width: 100%;
		max-height: 300px;
		margin: 0 auto;
		object-fit: cover;
	}
}