.button-top {
	position: fixed;
	z-index: 100;
	right: 85px;
	bottom: 15px;

	width: 56px;
	height: 56px;
	margin-left: -25px;

	cursor: pointer;
	transition: background-color 0.3s;

	border-radius: 50%;
	background-color: #8E0303;
	box-shadow: 0 2px 2px 0 rgb(0 0 0 / 14%), 0 1px 5px 0 rgb(0 0 0 / 12%), 0 3px 1px -2px rgb(0 0 0 / 20%);

	.icon-arleft {
		transform: rotate(90deg);

		font-size: 16px;
	}

	&:hover {
		transition: background-color 0.3s;

		background-color:lighten($color: #8E0303, $amount: 15%);
	}
}