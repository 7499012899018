$loader--width: 250px;
$loader-dot--size: 20px;

.container-lo {
	width: 100vw;
	height: 100vh;

	font-family: Helvetica;
}

.loader {
	position: absolute;
	inset: 0;

	width: $loader--width;
	height: $loader-dot--size;
	margin: auto;
  
	&--dot {
		position: absolute;

		width: $loader-dot--size;
		height: $loader-dot--size;

		border: 2px solid white;
		border-radius: 100%;
		background-color: black;
		animation: {
			name: loader; 
			timing-function: ease-in-out; 
			duration: 3s; 
			iteration-count: infinite;
		}
    
		&:first-child {
			animation-delay: 0.5s;

			background-color: #8CC759;
		}

		&:nth-child(2) {
			animation-delay: 0.4s;

			background-color: #8C6DAF;
		}

		&:nth-child(3) {
			animation-delay: 0.3s;

			background-color: #EF5D74;
		}

		&:nth-child(4) {
			animation-delay: 0.2s;

			background-color: #F9A74B;
		}

		&:nth-child(5) {
			animation-delay: 0.1s;

			background-color: #60BEEB;
		}

		&:nth-child(6) {
			animation-delay: 0s;

			background-color: #FBEF5A;
		}
	}

	&--text {
		position: absolute;
		top: 200%;
		right: 0;
		left: 0;

		width: 4rem;
		margin: auto;

		&::after {
			content: "Loading";      

			font-weight: bold;
			animation: {
				name: loading-text; 
				duration: 3s; 
				iteration-count: infinite;
			}
		}
	}
}

@keyframes loader {
	15% {
		transform: translateX(0);
	}

	45% {
		transform: translateX( $loader--width - $loader-dot--size );
	}

	65% {
		transform: translateX( $loader--width - $loader-dot--size );
	}

	95% {
		transform: translateX(0);
	}
}

@keyframes loading-text{
	0% {
		content: "Loading";
	}

	25% {
		content: "Loading.";
	}

	50% {
		content: "Loading..";
	}

	75% {
		content: "Loading...";
	}
}