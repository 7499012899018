
.menu {
	display: flex;

	margin-right: -20px;
	padding-top: 7px;

	list-style-type: none;
  
	&__item {
		position: relative;

		margin-right: 20px;

		cursor: pointer;
		transition: color 0.3s;
		text-transform: uppercase;

		color: $default;

		font-size: 14px;
  
		&::before {
			position: absolute;
			bottom: 0;
			left: 0;

			width: 0;
			height: 2px;
			margin-bottom: -4px;

			content: '';
			transition: width 0.4s;

			background-color: $default;
		}
  
		&.active::before {
			width: 100%;

			background-color: $default;
		}
  
		&:hover {
			transition: color 0.3s;

			color: lighten($color: $hover, $amount: 20%);

			&::before {
				width: 100%;

				background-color: $hover;
			}

			.menu {
				&__arrow {
					transition: transform 0.3s;
					transform: rotate(0deg);

					& path {
						fill: lighten($color: $hover, $amount: 20%);

						transition: color 0.3s;
					}
				}
			}

			.submenu {
				visibility: visible;

				transform: translateY(0);

				opacity: 1;
			}
		}
	}

	&__arrow {
		margin-bottom: 2px;

		transition: transform 0.3s;
		transform: rotate(-180deg);
	}
}

.submenu {
	position: absolute;
	top: 30px;
	left: -10px;

	display: flex;
	visibility: hidden;
	flex-direction: column;

	width: 100%;
	min-width: 180px;
	padding: 10px 0;

	transition: all .3s ease;
	transform: translateY(-10px);

	opacity: 0;
	color: $hover!important;
	border-radius: 15px;
	background-color: #FFF;
	box-shadow: 0 1px 4px rgb(0 0 0 / 30%);

	&__elem {
		position: relative;

		padding: 5px 10px 0;

		cursor: pointer;
		transition: color 0.3s;

		color: $title;

		&:hover {
			background-color: darken(#FFF, 10%);
		}
	}
}

@media screen and (width <= 1024px) {
	.submenu {
		position: relative;
		top: 0;

		display: none;

		height: 0;
		padding-left: 20px;

		border-radius: 0;
		background-color: transparent;
		box-shadow: none;

		font-size: 16px;

	}

	.menu {
		display: none;

		width: 0;
		height: 0;

		transition: opacity 0.3s, height 0.3s;

		opacity: 0;

		&.active {  
			position: relative;
			z-index: 20;
			top: 70px;
			left: 0;

			display: flex;
			align-items: center;
			flex-direction: column;

			width: 0;
			height: 100%;
			margin: 0 auto;
			padding-top: 55px;

			transition: opacity 0.3s, height 0.3s;  
			white-space: nowrap;

			opacity: 1;

			.menu__item {
				margin-right: 0;
				margin-bottom: 30px;

				font-size: 24px;
			} 
		}

		&__item {
			&:hover {
				.submenu {
					display: inline-block;

					transform: translateY(0);
				}
			}
		}
	}
}

@media screen and (width <= 468px) {
	.menu {
		&.active {
			top: 39px;
		}
	}
}