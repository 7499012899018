
@import "../../../scss/vars.scss"; 

.base-btn {
	display: flex;
	align-items: center;
	justify-content: center;

	height: 56px;
	padding: 5px;

	cursor: pointer;

	background-color: #FFF;
}

.checkout {
	&:hover {
		transition: background-color 0.3s;

		background-color: #74A736;
	}

	&:active {
		transition: background-color 0.3s;

		background-color: darken($color: #9DD558, $amount: 40%);
	}
}

.cancel {
	transition: background-color 0.3s color 0.3s;

	color:#8E0303;
	border: 1px solid $hover;
	border-radius: 13px;

	&:hover {
		transition: background-color 0.3s color 0.3s;

		color: #FFF;
		background-color: #F0B4AE;
	}

	&:active {
		transition: background-color 0.3s color 0.3s;

		background-color: darken($color: #F0B4AE, $amount: 20%);
	}
}

.continue {
	margin-right: 20px;

	transition: background-color 0.3s color 0.3s;

	color:#FFF;
	border: 1px solid #2C397C;
	border-radius: 13px;
	background-color: #2C397C;

	&:hover {
		transition: background-color 0.3s color 0.3s;

		color: $default;
		background-color: #FFF;
	}

	&:active {
		transition: background-color 0.3s color 0.3s;

		color: #FFF;
		background-color: lighten($color: #2C397C, $amount: 10%);
	}
}

.order {
	color: #FFF;
	border: 1px solid #9DD558;
	border-radius: 13px;
	background-color: #9DD558;

	&:hover {
		transition: background-color 0.3s color 0.3s;

		color: $default;
		background-color: #FFF;
	}

	&:active {
		transition: background-color 0.3s color 0.3s;

		color: #FFF;
		background-color: lighten($color: #9DD558, $amount: 10%);
	}
}

.clear {
	color: #FFF;
	border: 0 solid $hover;
	background-color: $active;

	&:hover {
		transition: background-color 0.3s;

		background-color: #F0B4AE;
	}

	&:active {
		transition: background-color 0.3s color 0.3s;

		color: $default;
		background-color: darken($color: #F0B4AE, $amount: 20%);
	}
}
