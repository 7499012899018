.card-details-page {
	padding-top: 30px;

	&__title {
		display: flex;
		justify-content: center;

		padding-top: 50px;

		font-size: 48px;
	}

	&__title-img {
		width: 100%;
		max-height: 300px;
		margin: 0 auto;
		object-fit: cover;
	}
}

.card-details {
  &__box {
    flex-direction: column;
  }

  &__img {
    height: 300px;
    margin-bottom: 30px;
    object-fit: contain;
  }

  &__price {
    // text-align: center;
    margin-bottom: 20px;
  }

  &__price-text {
    font-size: 24px;
  }
}