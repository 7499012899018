@import '../../../scss/mixins.scss';

.form-order {
	display: flex;
	align-items: center;
	justify-content: center;

	width: 100%;
	margin-bottom: 150px;

	&__form {
		max-width: 400px;
	}

	&__img {
    margin-left: 100px;
		border-radius: 45px;

		@include _1024 {
			max-height: 250px;
      margin-left: 0;
			margin-bottom: 30px;
		}
	}

	@include _1024 {
		flex-direction: column-reverse;

		margin-bottom: 100px;
	}

	@include _380 {
		margin-bottom: 50px;
	}
}