@import '../../../scss/vars.scss';
@import '../../../scss/mixins.scss';

.package {
	position: relative;

	margin-bottom: 50px;
	padding-top: 30px;

	.container {
		@include _380 {
			padding: 0;
		}
	}

	&::before {
		top: 0;
		left: 0;

		width: 1px;
		height: 100%;

		content: '';

		color: rgb(205 186 170 / 50%);
	}

	&__content {
		height: 100%;
		padding: 70px 20px;

		@include _767 {
      padding: 20px 0;
		}
	}

	&__content-block {
		.container {
			@include _380 {
				padding: 0;
			}
		}
	}

	&__row {
		width: 100%;
	}
}

.package-row {
	width: 100%;

	color: $default;
	border: 0.5px solid $hover;
	border-radius: 15px;
	background-color: #FFF;

	&__title {
		margin-bottom: 20px;

		font-size: 24px;
	}

	&__text {
		flex-grow: 1;

		font-size: 16px;
	}

	&__column-left {
		width: calc(50% - 20px);
		height: auto;
		min-height: 100%;
		margin-right: 20px;


		.package-btn {
			width: 200px;

			@include _767 {
				width: 100%;

				.autin-btn {
					min-width: 100%;
				}
			}
		}

		@include _1024 {
			width: 100%;
			margin-right: 0;
		}
	}

	&__img {
		width: calc(50%);
		max-height: 440px;
		margin-right: 0;

		border-radius: 10px;

		@include _1024 {
			display: none;
		}
	}
	@extend%libreBaskervilleItalic-400;

}