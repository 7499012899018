@import '../../../scss/vars.scss';
@import '../../../scss/mixins.scss';

.how-it-work {
	width: 100%;
	height: 100%;
	min-height: 400px;

	background-image: linear-gradient(rgb(255 255 255 / 50%), rgb(255 255 255 / 50%)), url("../../../assets/img/howItWork/tq.webp");
	background-repeat: repeat;
	background-size: 4%;

	&__inner {
		display: flex;
		align-items: center;
		flex-direction: column;

		width: 100%;
		padding: 100px 0;
	}

	&__title {
		max-width: 500px;
		margin: 0 auto;

		text-align: center;

		color: #131938;

		font-size: 36px;
		font-weight: 700;
		@extend%libreBaskerville-700;

		@include _767 {
			font-size: 24px;
		}
	}
}

.box {
	margin-bottom: 50px;
	padding: 40px 0;

	&__item {
		display: flex;
		align-items: center;
		flex-direction: column;

		@include _767 {
			margin-bottom: 20px;
		}
	}

	&__num {
		display: flex;
		align-items: center;
		justify-content: center;

		width: 40px;
		height: 40px;
		margin-bottom: 20px;
		padding-top: 4px;

		color: $disable;
		border-radius: 50%;
		background-color: #C7305A;

		font-size: 20px;
	}

	&__title {
		margin-bottom: 10px;

		color: #131938;

		font-size: 22px;
		@extend%libreBaskerville-700;
	}

	&__desc {
		max-width: 270px;

		text-align: center;

		color: #3E4159;

		font-size: 16px;
		@extend%libreBaskerville-400;
	}

	@include _767 {
		flex-direction: column;

		margin-bottom: 0;
	}
}
