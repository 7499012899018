.closed-btn {
	position: absolute;
	top: 30px;
	right: 30px;
	left: auto;

	display: flex;
	align-items: center;
	justify-content: center;

	width: 40px!important;
	height: 40px;
	padding: 9px;

	border: 1px solid #FFF;
	border-radius: 50%;
	background-color: transparent;
	background-color: #FFF;
	box-shadow: rgb(0 0 0 / 2%) 0 1px 3px 0, rgb(27 31 35 / 15%) 0 0 0 1px;

	&:hover {
		transition: background-color 0.3s border 0.3s;

		border: 1px solid #8E0303;
	}

	&__line{
		position: relative;

		display: inline-block;

		width: 20px;
		height: 0;
		padding: 10px;
		padding-left: 2px;

		&::before,
		&::after {
			position: absolute;
			right: 0;

			width: 20px;
			height: 2px;

			content: '';
			transition: transform 0.3s;

			background-color: #8E0303;
		}

		&::before {
			top: 9px;

			margin-bottom: 0;

			transform: rotate(45deg);  
		}

		&::after {
			bottom: 9px;

			transform: rotate(-45deg);
		}
	}
}