
@import '../../../scss/vars.scss';
@import '../../../scss/mixins.scss';

.category {
	padding: 70px 20px 30px;

	.swiper-wrapper {
		padding: 40px 0;
	}

	.swiper-button-prev,
	.swiper-button-next  {
		position: relative;
		z-index: 10;

		width: 50px;
		height: 50px;

		transition: background-color 0.3s;

		border: 0.5px solid #8E0303;
		border-radius: 50%;
		background-color: #FFF;

		&::before {
			position: absolute;

			display: block;

			content: url('../../../assets/icons/category/arrow-b.svg');
		}

		&:hover {
			transition: background-color 0.3s;

			background-color: darken($color: #FFF, $amount: 5%);
		}

		@include _1024 {
			display: none;
		}
	}

	.swiper-button-prev {
		bottom: 4px;
		left: 88%;

		&::before {
			right: 20px;
			bottom: 13px;

			transform: rotate(-180deg);
		}
	}

	.swiper-button-next {
		bottom: 32px;
		left: 95%;

		&::before {
			right: 16px;
			bottom: 6px;
		}
	}

	&__link {
		display: flex;
		align-items: flex-end;
		justify-content: center;

		width: auto;
		height: 250px;
		margin-right: 0;

		transition: all 0.6s;

		background-repeat: no-repeat;
		background-position: center center;
		background-size: 100%;

		&:hover {
			transition: background-size 0.6s;

			background-size: 120%;
			box-shadow: rgb(0 0 0 / 2%) 0 1px 3px 0, rgb(27 31 35 / 15%) 0 0 0 1px;
		}

		@include _1200 {
			width: auto;
			height: 200px;

			background-size: cover;
		}

		@include _1024 {
			width: auto;
			height: 157px;
		}

		@include _500 {
			width: auto;
			height: 80px!important;
			margin: 0 auto;
		}
	}

	&__title {
		color: #FFF;

		font-size: 22px;
		font-weight: bold;
	}
    
	&__text {
		display: flex;
		align-items: center;
		flex-direction: column;

		text-align: center;

		font-size: 20px;
		font-weight: 700;
		@extend%libreBaskerville-700;
	}

	&__text-accent {
		padding: 0 5px;

		color: $hover;
	}

	&__text-desc {
		color: $default;
	}
    
	&__meta-text {
		font-size: 24px;
		font-weight: 400;
	}

	@include _1200 {
		padding: 50px 0 20px;
	}
}

.swiper-pagination {
	.swiper-pagination-bullet-active {
		background: #8E0303!important;
	}
}

@media screen and (width <= 1024px) {
	.category {
		padding-top: 20px;

		&__text {
			margin-bottom: 50px;

			font-size: 24px;
		}

		&__meta-text {
			font-size: 14px;
		}

		&__content {
			& a:last-child {
				margin-right: 35px;
			}
		}

		&__link {
			&:hover {
				box-shadow: none;
			}
		}

		&__card-desc {
			font-size: 18px;
		}
	}
}

@media screen and (width <= 468px) {
	.category {
		padding-top: 0;
		padding-bottom: 10px;

		&__text{
			margin-bottom: 20px;

			font-size: 18px;
		}

		&__content {
			position: relative;

			flex-direction: column;

			margin-right: 0;

			& a {
				width: 100%;
				margin-bottom: 20px;
			}
		}

		&__link {
			width: 100%;
			height: 44px;
			padding: 12px;

			border-radius: 0 18px 18px;
		}
	}
}


