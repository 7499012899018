@import '../../scss/vars.scss';

.error {
	display: flex;
	align-items: center;
	justify-content: center;

	height: 100%;
	min-height: 600px;

	&__inner {
		height: 100%;
	}

	&__link {
		transition: color 0.3s;

		color: $hover;

		&:hover {
			color: lighten($color: $hover, $amount: 30%);
		}
	}
}

.subtitle {
	font-size: 36px;
	font-weight: 700;
	line-height: 45px;
}

.text {
	max-width: 600px;

	text-align: center;

	font-size: 18px;
	font-weight: 400;
	line-height: 28px;
}