@import 'mixins';

html {
	height: 100%;
}

html,
body {
	min-width: 320px;
}

#root {
	display: flex;
	flex-direction: column;

	min-height: 100vh;
}

body {
	color: $default;
	background-color: #FFF;

	font-size: 16px;

	@extend %helvetica-400;
}


main,
.fadeIn,
.fadeOut {
	flex-grow: 1;
}

.container {
	max-width: 1350px;
	margin: 0 auto;
	padding: 0 25px;

	@media screen and (width <= 767px) {
		padding: 0 15px;
	}
}

.container-big {
	max-width: 1340px;
	margin: 0 auto;
	padding: 0 25px;

	@media screen and (width <= 1024px) {
		padding: 0 40px;
	}

	@media screen and (width <= 767px) {
		padding: 0 15px;
	}
}

.container-max {
	max-width: 1440px;
	margin: 0 auto;
	padding: 0 25px;

	@media screen and (width <= 1024px) {
		padding: 0 40px;
	}

	@media screen and (width <= 767px) {
		padding: 0 15px;
	}
}

a,
button {
	display: block;

	cursor: pointer;

	outline: transparent;
}

.fadeIn {
	animation: 0.5s fadeIn forwards;
}

.fadeOut {
	animation: 0.5s fadeOut forwards;
}

@keyframes fadeIn {
	from {
		transform: translate(-20px, 0);

		opacity: 0;
	}

	to {
		transform: translate(0, 0);

		opacity: 1;
	}
}

@keyframes fadeOut {
	from {
		transform: translate(0, 0);

		opacity: 1;
	}

	to {
		transform: translate(-20px, 0);

		opacity: 0;
	}
}