@import '../../../scss/vars.scss';
@import '../../../scss/mixins.scss';

.slider {
	position: relative;

	overflow: hidden;

	margin-top: -50px;
	margin-bottom: 10px;

	& .slide-list {
		display: flex;

		width: 100%;
		height: 100%;

		transition: transform 0.5s ease-in-out;
	}

	@include _1024 {
		height: 100%!important;
	}
}

.slide {
	position: relative;

	height: 100%;

	video {
		@include _767 {
			min-height: 420px;
			object-fit: cover;
		}
	}

  &__desktop {
    @include _640 {
			display: none;
		}
  }

	&__mobile {
    display: none;
		position: relative;

		width: 100%;
		height: 100%;
		margin: 0 auto;
		object-fit: cover;

		@include _640 {
			display: inline-flex;
		}
	}

	&__content {
		position: absolute;
		z-index: 200;
		top: 80%;
		left: 40%;

		display: flex;
		align-items: flex-end;
		flex-direction: column;

		width: 100%;

		transform: translate(-50%, -50%); 

		@extend %libreBaskerville-700;

		@include _767 {
			top: 60%;
			left: -10px;

			transform: translate(0, 0); 

			.contacts-btn {
				display: inline-flex;
			}
		}

		@include _640 {
			left: 0;

			align-items: center;
		}
	}

	& .slide-title {
		margin-top: 10px;
		margin-bottom: 40px;

		text-align: center;

		color: #FFF!important;

		font-size: 48px;

		@include _1024 {
			font-size: 32px;
		}

		@include _767 {
			margin-bottom: 10px;

			font-size: 28px;
		}

		@include _320 {
			font-size: 22px;
		}
	}

	& .slide-subtitle {
		margin-bottom: 50px;

		text-align: center;

		color: #FFF;

		font-size: 18px;
		font-weight: 600;
		line-height: 28px;
	}
}
