.info-block {
	display: block;
	align-items: center;
	justify-content: center;
	text-align: center;

	color: #8E0303;

	font-size: 22px;

	&__block {
		position: relative;

		display: flex;
		align-items: center;

		padding: 0 15px;

		&::before,
		&::after {
			position: absolute;

			display: block;

			width: 10px;
			height: 10px;
			padding-bottom: 2px;

			content: '';

			border-radius: 50%;
			background-color: #484848;
		}

		&::after {
			left: 0;
		}

		&::before {
			right: 0;
		}
	}

  &__headline {
    text-transform: uppercase;
  }
}