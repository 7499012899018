.products-card__text {
	display: none;
}

.products-card {
	&__item {
		min-width: 250px;
		margin-bottom: 20px;
		padding: 14px;

		transition: box-shadow 0.3s, background-color 0.3s;

		color: #131938;
		border: 1px solid #8E0303;
		border-radius: 25px 25px 25px 0;
		background-color: #FFF;

		&:hover {
			transition: box-shadow 0.3s, background-color 0.3s;

			background-color: rgb(255 249 249);
			box-shadow: rgb(0 0 0 / 24%) 0 3px 8px;
		}
	}

	&__desc {
		display: -webkit-box;
		overflow: hidden;

		width: 100%;
		max-width: 250px;

		text-align: center;
		-webkit-line-clamp: 5;
		-webkit-box-orient: vertical;
	}

	&__image {
		position: relative;

		display: flex;
		align-items: center;
		justify-content: center;

		width: 100%;
		max-height: 200px;
		margin: 0;

		border-radius: 25px 25px 0 0;
		background-color: #FFF;
	}

	&__title {
		display: flex;
		align-items: center;
    // justify-content: center;
		flex-direction: column;
    text-align: center;

		// max-width: 250px;
		margin-top: 12px;
		margin-bottom: 12px;

		text-transform: none;

		font-style: italic;
	}

	&__weight {
		position: absolute;
		top: 12px;
		right: 12px;
	}

	&__price {
		font-size: 38px;
		font-weight: 400;
	}

	&__state {
		margin-bottom: 5px;

		text-align: center;

		color: green;
	}
}

@media screen and (width <= 1024px) {
	.top-products {
		padding-bottom: 70px;
	}
}

@media screen and (width <= 767px) {
	.top-products {
		padding-bottom: 0;

		&__description {
			margin-bottom: 10px;
		}
	}
}