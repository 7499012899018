$default: #131938; //шрифт
$title: #1D1D1f; // заголовки bg
$hover: #8E0303;
$disable: #E8E8E8;
$active: #f20505;

%helvetica-400 {
  font-family: "HelveticaNeueCyr", sans-serif;
  font-weight: 400;
}

%helvetica-700 {
  font-family: "HelveticaNeueCyr";
  font-weight: 700;
}

%helvetica-900 {
  font-family: "HelveticaNeueCyr";
  font-weight: 900;
}


%montserrat-400 {
  font-family: 'Montserrat';
  font-weight: 400;
}

%montserrat-700 {
  font-family: 'Montserrat-Bold';
  font-weight: 700;
}

%libreBaskerville-400 {
  font-family: 'LibreBaskerville';
  font-weight: 400;
}

%libreBaskervilleItalic-400 {
  font-family: 'LibreBaskerville-Italic';
  font-weight: 400;
}

%libreBaskerville-700 {
  font-family: 'LibreBaskerville-Bold';
  font-weight: 700;
}

// %oswald-500 {
//   font-family: 'Oswald', sans-serif;
//   font-weight: 500;
// }

// %vegan-400 {
//   font-family: 'Vegan', sans-serif;
//   font-weight: 400;
// }

// %syncopate-700 {
//   font-family: 'Syncopate', sans-serif;
//   font-weight: 700;
// }

@function max($numbers...) {
  @return m#{a}x(#{$numbers});
}

@function min($numbers...) {
  @return m#{i}n(#{$numbers});
}
