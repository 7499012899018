.menu-mobile {
	position: fixed;
	z-index: 0;
	inset: 50px 0 0;

	display: flex;
	align-items: center;
	flex-direction: column;

	width: 100%;
	margin: 0;
	padding: 0;

	background-color: #FFF;

	&__inner {
		width: 80%;
		// margin-top: 50px;

		.menu {
			position: relative;

			display: flex;
			align-items: flex-start;
			flex-direction: column;

			width: 100%;

			opacity: 1;

			&__item {
				width: 100%;
				margin-bottom: 20px;

				font-size: 16px;

				&::before {
					display: none;
				}
			}
		}
	}

	&__meta {
		display: flex;
		align-items: center;
		justify-content: space-between;
	}

  &__contacts {
    padding-top: 100px;
  }
}